.background-list{
	position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 999;
    flex-wrap: wrap;
    .content{
    	background:#FFFFFF;
    	border-radius: 5px;
    	box-shadow: 0 0 1em #808080;
    	overflow: auto;
        width: 40%;
    }
    .buttom{
        text-align: center;
        margin-bottom: 5px;
        .close{
            width: 30%;
            text-align: center;
            display: inline-block;
            align-self: center;
        }
    }
}

.background-list{
	h5{
		color: var(--color-primary);
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
	}

    ul{
		padding:7px;
		list-style: none;
	}

	span{
		color:#808080;
		font-size: 0.8em;
	}
	.span-error{
		color:#808080;
		font-size: 1em;
	}

	b{
		color:#808080;
		font-size: 0.8em;
	}

	.total{
		color:#337ab7;
		font-size: 1.1em;
		padding-top:100px;
	}

}
