.page-rotes{
    .row {
        width: 100%;
        padding-left: 3%;
        .third-width {
            vertical-align : top;
            width: 30%;
            display: inline-block;
            margin-right: 3%;
        }
    }
    .lineButton {
        text-align: center;
        height : 40px;
        button {
            height: 30px;
            padding: 3px;
            margin: 5px;
        }
    }
    .trSelected {
        background: var(--color-primary-dark) !important;
        color: var(--color-white);
    }
    .icon-right {
        height: 20px;
        width: 30px;
        text-align: center;
        font-size: 120%;
        float: right;
    }

    .formGroup {
        margin-bottom: 10px;
        width: 100%;
        label {
            left:0;
        }
        input {
            width: 100%;
            right: 0;
            height: 30px;
        }
        button {
            margin: 5px;
            height: 30px;
            padding: 2px;
        }
    }
}
