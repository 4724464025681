.page-config{

    .hear td{
        cursor: pointer;
    }
    .miniImg {
        width: 30%;
    }

    .legend {
        font-size: 0.8em;
    }

    .div-container{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-bottom: 20px;
        width: 100%;
        .div-input{
            padding-right: 10px;
            width: 25%;

            input {
                width: 100%;
            }

            .deleteButton {
                position: relative;
                top: 10px;
                right: 10px;
                float: left;

                a {
                    text-decoration: none;
                    color: brown;
                    font-size: 20px;
                }
            }
        }
        .label-input{
            font-size: 18px;
            font-weight: 300;
        }

        .legend-input{
            font-size: 11px;
            color: #A9A9A9;
        }

        .divider{
            background-color:  #C0C0C0;
        }

        input{
            padding-left: 3px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 3px;
            width: 200px;
            border-radius: 5px;
            font-size: 16px;
            margin-top: 7px;
        }
        .div-text{
            width: 100%;
            textarea{
                resize: none;
                padding-left: 3px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 3px;
                width: 100%;
                height: 400px;
                border-radius: 5px;
                margin-top: 7px;
            }

        }
    }

}
