.page-login {
  display: flex;
  min-height: 100vh;
  padding: 50px;
  background: var(--color-bg-light);
  .apresentation {
    background-image: linear-gradient(-140deg, var(--color-primary), var(--color-primary-degrade));
    flex: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px;
    box-sizing: border-box;
    border-radius: 20px 0px 0px 20px;
    .description {
        align-items: center;
      h1 {
        font-size: 40px;
        color: var(--color-white);
        font-weight: 400;
        text-align: center;
      }
      img {
        align-content: center;
        margin-top: 20px;
        margin-left: 40px;
      }
    }
  }
  .formLogin {
    background: var(--color-white);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px;
    border-radius: 0px 20px 20px 0px;
    flex-wrap: wrap;
    .boxLogin {
      width: 51%;
      padding-bottom: 50px;
    }
    h2 {
      font-size: 30px;
      margin-bottom: 50px;
      color: var(--color-primary);
      font-weight: 400;
      text-align: center;
    }
  }
}
